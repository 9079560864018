import { useState } from "react"

const AddTask = ({ onAdd }) => {
  const [text, setText] = useState('')
  const [day, setDay] = useState('')
  const [reminder, setReminder] = useState(false)

  const onSubmit = (e) => {
    e.preventDefault()

    if (!text) {
      alert('Please add a task')
      return
    }

    onAdd({ text, day, reminder })
    setText('')
    setDay('')
    setReminder(false)
  }

  return (
    <form className="add-form" onSubmit={onSubmit}>
        <div className="form-control">
          <label htmlFor="task">Task</label>
          <input type="text" name="task" id="task" placeholder="Add Task" 
            value={text} 
            onChange={(e) => setText(e.target.value)} />
        </div>
        <div className="form-control">
          <label htmlFor="day">Day & FaTimes</label>
          <input type="text" name="day" id="day" placeholder="Add day & time"
            value={day} 
            onChange={(e) => setDay(e.target.value)} />
        </div>
        <div className="form-control form-control-check">
          <label>Set reminder</label>
          <input type="checkbox"
            value={reminder} 
            checked={reminder && 'checked'}
            onChange={(e) => setReminder(e.currentTarget.checked)} />
        </div>
        <input type="submit" value="Save task" className="btn btn-block" />
    </form>
  )
}

export default AddTask