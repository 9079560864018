import { useState } from 'react'
import Header from './components/Header'
import Tasks from './components/Tasks'
import AddTask from './components/AddTask'

function App() {

  const [showAddTask, setShowAddTask] = useState(true)
  const [tasks, setTasks] = useState([
    {
      "id": 1,
      "text": "Doctors Appoinment",
      "day": "Feb 7th at 11:30am",
      "reminder": true,
    },
    {
      "id": 2,
      "text": "Meeting as School",
      "day": "Feb 6th as 1:30pm",
      "reminder": true,
    },
    {
      "id": 3,
      "text": "Food shopping",
      "day": "Feb 5th at 2:30pm",
      "reminder": false,
    }
  ])

  // Add Task
  const addTask = (task) => {
    const id = Math.floor(Math.random() * 10000) + 1
    const newTask = { id, ...task }
    setTasks([...tasks, newTask])
  }

  // Delete Task
  const deleteTask = (id) => {
    setTasks(tasks.filter(
      (task) => task.id !== id
    ))
  }

  // Toggle Task
  const toggleTask = (id) => {
    setTasks(tasks.map(
      (task) => task.id === id ? { ...task, reminder: !task.reminder} : task
    ))
  }

  return (
    <div className="container">
      <Header title="Hello Akos" onAdd={() => setShowAddTask(!showAddTask)} showAdd={showAddTask} />
      {showAddTask && <AddTask onAdd={addTask} />}
      {tasks.length > 0 ? <Tasks tasks={tasks} onDelete={deleteTask} onToggle={toggleTask} /> : 'No Tasks To Show'}
    </div>
  );
}

export default App;
